/* =================== CLIENTES =================== */
jQuery(document).ready(function($) {
  $('.carrosel-clientes').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [{
        breakpoint: 1119,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }
    ]
  });
});
