/* =================== CADASTRO =================== */
jQuery(document).ready(function($){
  var enviando_formulario = false;
  $('#form-user-documento').on('submit', function(e) {
    e.preventDefault(); 

    var obj = this;   
    var form = $(obj);
    var submit_btn = $('#cadastrar');            
    var submit_btn_text = submit_btn.text();
    var dados = new FormData(obj);    

    function volta_submit() {
      submit_btn.attr('disabled', false);            
      submit_btn.text(submit_btn_text);
      enviando_formulario = false;
      hideLoading();           
    }

    if (!enviando_formulario) {              
      $.ajax({
        url: form.attr('action'),
        type: form.attr('method'),
        data: dados,
        processData: false,
        cache: false,
        contentType: false,    
        beforeSend: function() {  
          showLoading();                  
          enviando_formulario = true;                   
          submit_btn.attr('disabled', true);
          submit_btn.text('Aguarde...');                    
        },           
        success: function(data){
          console.log(data);
          var response = $.parseJSON(data);
          if (response.status == "ok") {
            form.find('.campo').val('');
            if(response.acao == "cadastrar"){
              window.location.href = $('#infos').data('url-base')+"minha-conta/documentos-enviados/cad-success/";
            }else{
              window.location.href = $('#infos').data('url-base')+"minha-conta/documentos-enviados/edit-success/";
            }
          } else {
            hideLoading();
            showAlert('Erro', 'Não foi possível realizar essa operação.', 'error');
          }
        },
        error: function(xhr, type, exception) {  
          volta_submit();  
          showAlert('Erro', 'Não foi possível realizar essa operação.', 'error');             
          console.log("ajax error response type "+type);
        }
      });
    }
  });
});


    // REMOVER
    var enviando_formulario = false;
    $('#form-remove-documento').on('submit', function(e) {
      e.preventDefault();

      var obj = this;
      var form = $(obj);
      var dados = new FormData(obj);
      var idRemove = $('#id_remove').val();

      function volta_submit() {
        enviando_formulario = false;
        hideLoading();
      }

      if (!enviando_formulario) {
        $.ajax({
          url: form.attr('action'),
          type: form.attr('method'),
          data: dados,
          processData: false,
          cache: false,
          contentType: false,
          beforeSend: function () {
            closeModal('modal-confirm');
            showLoading();
            enviando_formulario = true;
          },
          success: function (data) {
            console.log(data);
            closeModal('modal-confirm');
            if (data == "ok") {
              $('#id_remove').val('');
              setTimeout(() => {
                volta_submit();
                $('#documento-' + idRemove).fadeOut();
                showAlert('Sucesso', 'Documento removido com sucesso.', 'success');
              }, 1500);
            } else {
              volta_submit();
              showAlert('Erro', 'Não foi possível realizar essa operação.', 'error');
            }
          },
          error: function (xhr, type, exception) {
            volta_submit();
            showAlert('Erro', 'Não foi possível realizar essa operação.', 'error');
            console.log("ajax error response type " + type);
          }
        });
      }
    });
